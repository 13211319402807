import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import FloatingCircle, { CirclesContainer } from '../FloatingCircle'
import { CircleSection } from '../sections'

const CirclesPara = ({ isMobile }) => {
  const ref = useRef()
  return (
    <CircleSection bg="pinklightest" ref={ref}>
      <CirclesContainer>
        <FloatingCircle
          sectionRef={ref}
          depth={1}
          radius={isMobile ? 272 : 467}
          align={['left', 'top']}
          position={isMobile ? [-61, 72] : [-262, 118]}
          fill="#fdf6f8"
        />
        <FloatingCircle
          sectionRef={ref}
          depth={2}
          radius={isMobile ? 119 : 205}
          align={['right', 'bottom']}
          position={isMobile ? [16, 25] : [30, 123]}
          fill="#fdeff4"
        />
        <FloatingCircle
          sectionRef={ref}
          depth={2.2}
          radius={isMobile ? 78 : 134}
          align={['left', 'bottom']}
          position={isMobile ? [52, 118] : [372, 48]}
          fill="#ffffff"
        />
      </CirclesContainer>
      <p>
        Doule su u posljednje vrijeme prihvaćene od strane bolničkog osoblja, a
        sve više rodilja kao pratnju na porodu odabire upravo doulu.
      </p>
    </CircleSection>
  )
}
CirclesPara.propTypes = {
  scroll: PropTypes.number.isRequired,
}

export default React.forwardRef(CirclesPara)
