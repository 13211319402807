import React from 'react'
import PropTypes from 'prop-types'

const SvgPorod = ({ width = 120 }) => (
  <svg width={width} fill="none" viewBox="0 0 120 120">
    <path
      d="M120 60c0 27.283-18.209 50.311-43.138 57.598a58.88 58.88 0 01-7.77 1.718A60.024 60.024 0 0160 120a60.497 60.497 0 01-12.147-1.229 52.042 52.042 0 01-1.527-.339 57.531 57.531 0 01-3.74-1C17.943 109.97 0 87.082 0 60 0 35.138 15.123 13.807 36.67 4.702a58.89 58.89 0 015.777-2.093A59.934 59.934 0 0160.001 0 60.033 60.033 0 0172.16 1.234C99.466 6.854 120 31.03 120 60z"
      fill="#F29EBA"
    />
    <path
      d="M47.647 47.678c-1.496-.299-2.435-1.213-3.176-2.425-.49-.797-1.023-1.584-1.401-2.433-2.484-5.588-7.07-7.599-12.835-7.459-4.658.113-8.872 1.674-12.525 4.558-5.629 4.447-7.14 11.285-4.21 18.531 1.48 3.666 3.919 6.624 6.873 9.18.997.863 2.01 1.762 3.145 2.412 5.334 3.062 10.137 6.827 14.718 10.898.778.692 7.713 5.732 9.482 6.711 11.1 6.152 22.757 9.176 35.422 5.937 2.78-.711 5.444-1.806 7.876-3.37 6.878-4.412 7.737-13.569 1.797-19.166-.763-.719-1.6-1.35-2.4-2.028-.286-.243-.746-.424-.683-.86.078-.544.647-.542 1.049-.63 2.4-.524 4.812-.55 7.218-.06 3.83.783 6.131-1.705 4.478-5.37-1.361-3.025-2.423-6.13-3.64-9.188-.642-1.626-1.874-2.85-3.288-3.853-1.458-1.032-2.737-.436-2.864 1.341-.126 1.724.306 3.396.603 5.075.088.488.18.976.249 1.464.192 1.374-.324 2.016-1.765 1.689-1.663-.382-3.38-.614-4.98-1.176-2.784-.984-5.408-.657-8.025.484-1.21.53-2.417 1.07-3.641 1.574-.332.136-.736.405-1.07.028-.291-.334-.116-.721.014-1.092.553-1.568 1.537-2.763 2.965-3.638 1.056-.645 2.388-1.166 2.208-2.767-.159-1.456-2.537-3.886-3.94-4.134-1.707-.298-2.85.477-3.657 1.925-1.47 2.642-2.957 5.279-4.44 7.914-.063-2.818.532-5.569.532-8.378-.001-3.19-2.059-4.71-5.062-3.65-.39.137-.737.234-1.083-.047-1.313-1.054-2.016-.16-2.59.866-.912 1.625-.513 3.662.895 4.955 2.565 2.356 2.055 6.419-1.214 7.573-2.993 1.059-5.434 2.868-7.983 4.59-.855.577-1.665 1.26-2.834 1.523.014-.745.536-1.1.895-1.525.888-1.054 1.83-2.06 2.705-3.125.529-.643 1.095-1.436.84-2.255-.764-2.453-.91-5.153-2.725-7.211-.319-.364-.496-.777-.363-1.214.44-1.444-.493-1.958-1.57-2.174z"
      fill="#fff"
    />
    <path
      d="M90.463 28.166c1.523 3.749 3.416 8.415-.512 13.836-6.213 8.567-11.342 19.296-11.391 19.402l-3.588-1.705c.215-.45 5.33-11.158 11.765-20.027 2.633-3.637 1.435-6.591.045-10.013-1.386-3.419-3.113-7.668.934-11.715 2.724-2.726.986-7.41-1.131-11.076a60.487 60.487 0 015.873 3.531c1.06 3.617.993 7.426-1.932 10.351-2.094 2.091-1.495 3.881-.063 7.416z"
      fill="#fff"
    />
  </svg>
)
SvgPorod.propTypes = {
  width: PropTypes.number,
}

export default SvgPorod
