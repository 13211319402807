import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import useMedia from 'use-media'
import Layout from '../components/layout'
import {
  TitleSection,
  ParagraphSection,
  OverviewTitle,
} from '../components/sections'
import Slider from '../components/Slider'

import CirclesPara from '../components/home/CirclesPara'

import SEO from '../components/seo'
import TrudnocaIcon from '../icons/Trudnoca'
import PorodIcon from '../icons/Porod'
import MajcinstvoIcon from '../icons/Majcinstvo'
import useIntersect from '../hooks/useIntersect'
import useOnScreen from '../hooks/useOnScreen'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      jaSamDoulaSanja: file(
        relativePath: { eq: "home/ja-sam-doula-sanja.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jaSamDoulaSanjaMobile: file(
        relativePath: { eq: "home/ja-sam-doula-sanja-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [subsection, setSubsection] = useState()
  const isMobile = useMedia({ maxWidth: 500 })
  const smallIcons = useMedia({ maxWidth: 650 })

  // const threshold = []
  // for (let i = 0; i < 100; i += 1) threshold.push(i / 100)
  // const [circlesRef, entry] = useIntersect({ threshold })
  // const scroll =
  //   entry && entry.boundingClientRect ? entry.boundingClientRect.y : 0

  const vasePovjerenjeRef = useRef()
  const vasePovjerenjeIntercept = useOnScreen(vasePovjerenjeRef)

  useEffect(() => {
    if (vasePovjerenjeIntercept) {
      setSubsection('Vaše povjerenje')
    } else {
      setSubsection()
    }
  }, [vasePovjerenjeIntercept])

  return (
    <Layout subsection={subsection}>
      <SEO title="Home" />
      <BackgroundImage
        Tag="section"
        fluid={
          isMobile
            ? data.jaSamDoulaSanjaMobile.childImageSharp.fluid
            : data.jaSamDoulaSanja.childImageSharp.fluid
        }
        backgroundColor="#c4c4c4"
      >
        <TitleSection img>
          <div>
            <h2>Ja sam doula Sanja</h2>
            <p className="index">
              Vaša podrška kroz trudnoću,
              <br />
              na porodu i u danima babinja
            </p>
          </div>
        </TitleSection>
      </BackgroundImage>
      <OverviewTitle>
        <span>
          <div>
            <TrudnocaIcon width={smallIcons ? 60 : 120} />
            <h3>Trudnoća</h3>
            <p>Pripremam Vas za lijepo iskustvo rađanja i dane majčinstva</p>
          </div>
          <div>
            <PorodIcon width={smallIcons ? 60 : 120} />
            <h3>Porod</h3>
            <p>
              Tijekom poroda pružam Vam psihičku, emotivnu i fizičku podršku
            </p>
          </div>
          <div>
            <MajcinstvoIcon width={smallIcons ? 60 : 120} />
            <h3>Majčinstvo</h3>
            <p>
              Posjećujem Vas u danima majčinstva, pomažem savjetom i toplom
              riječi
            </p>
          </div>
        </span>
      </OverviewTitle>
      <ParagraphSection>
        <span className="icon">
          <TrudnocaIcon width={smallIcons ? 90 : 180} />
        </span>
        <div>
          <h3>Trudnoća</h3>
          <p>
            Trudnoća je čarobno razdoblje u životu svake žene, no kroz trudnoću
            i porod znaju se javljati strahovi, nedoumice i mnoga pitanja na
            koja želimo znati odgovore. Kako bi trudnoća bila mirna, ugodna i
            lijepa,{' '}
            <b>
              strahove ćemo eliminirati, na pitanja ćemo odgovoriti, a zajedno s
              partnerom napravit ćemo i plan poroda.
            </b>{' '}
            U terminu ćemo biti pozitivni i spremni za lijepo iskustvo rađanja.
          </p>
        </div>
      </ParagraphSection>
      <ParagraphSection light>
        <div>
          <h3>Porod</h3>
          <p>
            S majkom ću se gibati, opuštati, disati, olakšavati bol u trudu,
            dati joj emotivnu, psihičku i fizičku podršku. Ni na jedan način
            neću zamijeniti bolničko osoblje.{' '}
            <b>
              Pružit ću Vam svu nemedicinsku podršku, pažnju, pogled, dodir,
              zagrljaj i riječi podrške.
            </b>
          </p>
        </div>
        <span className="icon">
          <PorodIcon width={smallIcons ? 90 : 180} />
        </span>
      </ParagraphSection>
      <ParagraphSection>
        <span className="icon">
          <MajcinstvoIcon width={smallIcons ? 90 : 180} />
        </span>
        <div>
          <h3>Majčinstvo</h3>
          <p>
            Nakon poroda posjetit ću svoju doula obitelj u njihovom domu.{' '}
            <b>Vaša doula bit će tu za vas uvijek kada ćete je trebati.</b>
          </p>
        </div>
      </ParagraphSection>
      <CirclesPara isMobile={isMobile} />
      <Slider ref={vasePovjerenjeRef} />
    </Layout>
  )
}

export default IndexPage
