import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTransition, useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { colors } from '../theme'
import testimonials from './testimonials'

const TextContainer = styled.div`
  width: 500px;
  height: 240px;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: ${colors.greymid};
    margin-bottom: 25px;
  }
  small {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.greymid};
    margin-bottom: 40px;
  }

  @media (max-width: 1280px) {
    text-align: center;
    width: 70%;
    height: 200px;
    max-width: 800px;
  }

  @media (max-width: 650px) {
    width: 100%;
    max-width: 420px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }

  @media (max-width: 400px) {
    height: 400px;
  }
`

const AnimatedContainer = styled(animated.div)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Text = ({ current, isMobile, incrementStep, decrementStep }) => {
  const previous = useRef(current)

  const transitions = useTransition(current, item => item, {
    from: {
      opacity: 0,
      transform: `translate3d(${
        previous.current < current ? '20px' : '-20px'
      }, 0, 0)`,
    },
    enter: { opacity: 1, transform: `translate3d(0px, 0, 0)` },
    leave: {
      opacity: 0,
      transform: `translate3d(${
        previous.current < current ? '-20px' : '20px'
      }, 0, 0)`,
    },
  })

  const [styles, setStyles] = useSpring(() => ({
    transform: 'translate3d(0px, 0, 0)',
    opacity: 1,
  }))

  useEffect(() => {
    previous.current = current
  }, [current, transitions])

  const bind = useDrag(({ down, movement: [mx], cancel, canceled }) => {
    setStyles({
      transform: `translate3d(${down ? mx : 0}, 0, 0)`,
      opacity: down ? (mx > 0 ? 1 - mx / 20 : 1 + mx / 20) : 1,
    })
    if (canceled) return
    if (mx > 20) {
      decrementStep()
      return cancel()
    }
    if (mx < -20) {
      incrementStep()
      return cancel()
    }
  })

  return (
    <TextContainer isMobile={isMobile}>
      {transitions.map(({ item, key, props }) => (
        <AnimatedContainer
          {...bind()}
          key={key}
          style={{
            ...props,
          }}
          isMobile={isMobile}
        >
          <AnimatedContainer style={styles}>
            <p>{testimonials[item].text}</p>
            <small>{testimonials[item].name}</small>
          </AnimatedContainer>
        </AnimatedContainer>
      ))}
    </TextContainer>
  )
}
Text.propTypes = {
  current: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default Text
