import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import useMedia from 'use-media'
import Arrow from '../../icons/Arrow'
import DotStack from './DotStack'
import Text from './Text'

const Container = styled.section`
  min-height: 90vh;
  width: 100%;
  padding: 60px 60px 100px;
  background: ${props => `${props.bg}` || ''};

  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${props =>
    props.isMobile
      ? `
    flex-direction: column;
    justify-content: flex-start;
    padding: 60px 30px 140px; !important
  `
      : ''}

  @media (max-width: 850px) {
    padding: 60px 40px;
  }
  @media (max-width: 670px) {
    padding: 60px 30px;
    min-height: 93vh;
  }
  @media (max-width: 650px) {
    min-height: 83vh !important;
  }
`

const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdeff3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  :focus {
    outline: none;
  }

  ${props =>
    props.pointLeft
      ? `
      left: ${props.isMobile ? 0 : '75px'};
      transform: rotate(180deg);
      `
      : `
      right: ${props.isMobile ? 0 : '75px'};
  `}
`

const ImageContainer = styled.div`
  position: relative;
  width: 232px;
  height: 249px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1270px) {
    width: 100%;
    max-width: 280px;
    height: auto;
    margin-bottom: 50px;
    div {
      width: 80px;
    }
  }
`

const Slider = (props, ref) => {
  const data = useStaticQuery(graphql`
    query {
      testimonial1: file(
        relativePath: { eq: "testimonials/testimonial1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 118) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonial2: file(
        relativePath: { eq: "testimonials/testimonial2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 112) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonial3: file(
        relativePath: { eq: "testimonials/testimonial3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 112) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const isMobile = useMedia({ maxWidth: 1270 })
  const total = 10
  const [current, setCurrent] = useState(0)

  const incrementStep = useCallback(
    () => setCurrent(prev => (prev + 1) % total),
    []
  )
  const decrementStep = useCallback(
    () => setCurrent(prev => (prev - 1 >= 0 ? prev - 1 : total - 1)),
    []
  )
  return (
    <Container ref={ref} isMobile={isMobile}>
      <ImageContainer isMobile={isMobile}>
        <Img
          fluid={data.testimonial1.childImageSharp.fluid}
          style={
            isMobile
              ? { position: 'relative', width: 80, height: 80 }
              : {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 118,
                  height: 118,
                }
          }
        />
        <Img
          fluid={data.testimonial2.childImageSharp.fluid}
          style={
            isMobile
              ? { position: 'relative', width: 80, height: 80 }
              : {
                  position: 'absolute',
                  bottom: 36,
                  left: 0,
                  width: 98,
                  height: 98,
                }
          }
        />
        <Img
          fluid={data.testimonial3.childImageSharp.fluid}
          style={
            isMobile
              ? { positon: 'relative', width: 80, height: 80 }
              : {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: 74,
                  height: 74,
                }
          }
        />
      </ImageContainer>

      <DotStack
        current={current}
        total={total}
        setCurrent={setCurrent}
        isMobile={isMobile}
      />

      <Text
        current={current}
        isMobile={isMobile}
        incrementStep={incrementStep}
        decrementStep={decrementStep}
        setCurrent={setCurrent}
      />

      <div
        style={
          isMobile
            ? {
                width: 120,
                margin: '140px 0 30px',
                display: 'inline-block',
                position: 'relative',
              }
            : {}
        }
      >
        <ArrowButton pointLeft onClick={decrementStep} isMobile={isMobile}>
          <Arrow />
        </ArrowButton>
        <ArrowButton onClick={incrementStep} isMobile={isMobile}>
          <Arrow />
        </ArrowButton>
      </div>
    </Container>
  )
}

export default React.forwardRef(Slider)
