import React from 'react'
import PropTypes from 'prop-types'

const SvgTrudnoca = ({ width = 120 }) => (
  <svg width={width} fill="none" viewBox="0 0 120 120">
    <path
      d="M120 60c0 27.283-18.21 50.311-43.138 57.597a58.831 58.831 0 01-7.771 1.718 60.89 60.89 0 01-11.78.624 60.36 60.36 0 01-9.457-1.169 48.935 48.935 0 01-1.528-.339 56.32 56.32 0 01-3.741-1.001C17.943 109.969 0 87.08 0 60 0 35.138 15.123 13.807 36.67 4.702a58.833 58.833 0 015.777-2.093A59.973 59.973 0 0160.001 0a60.15 60.15 0 0112.158 1.232C99.465 6.856 120 31.03 120 60.001z"
      fill="#F29EBA"
    />
    <path
      d="M79.184 39.871a.357.357 0 01.068-.629c4.013-1.586 7.29-4.26 7.487-8.527.29-6.221-6.51-9.835-11.377-11.812-3.336-1.36-8.446-5.514-10.72-7.843-2.28-2.333-3.99-5.365-5.115-8.57-.282-.805-.398-1.66-.414-2.482a59.91 59.91 0 00-16.668 2.601c-.672 3.312-1.392 8.474-1.772 11.304a11.156 11.156 0 01-1.24 3.836c-2.686 4.977-8.163 15.167-11.545 19.945-3.878 5.484-11.213 13.742-10.283 16.778.926 3.034 4.804 5.563 8.848 8.513 3.507 2.555 16.688 9.631 20 11.48-1.139 1.705-2.33 3.418-3.479 5.127-4.127 6.15-5.986 11.282-5.732 17.69.244 6.161 8.433 13.971 9.053 19.724.03.227.04.451.04.674 0 .238-.004.487-.011.751.506.12 1.017.233 1.527.338 3.073.63 6.23 1.027 9.456 1.169h5.4a60.257 60.257 0 006.382-.623 58.833 58.833 0 006.924-1.479c.045-.612.098-1.301.156-2.04.46-5.9 1.264-15.163 2.06-16.706 1.01-1.968 4.296-2.755 10.968-7.485 7.953-5.64 10.885-14.828 10.885-22.358.001-14.738-17.563-27.184-20.897-29.376zM52.585 61.47c-.225 1.725-.818 3.499-1.638 5.306-2.235-2.141-8.082-6.615-12.435-9.86-3.726-2.777-6.314-3.473-7.38-3.65a.354.354 0 01-.238-.544c1.689-2.472 5.348-5.098 9.303-7.942 3.36-2.421 5.758-4.897 6.587-5.799a.356.356 0 01.57.065c.595 1.035 2.14 3.858 3.21 6.995 1.347 3.964 2.78 9.695 2.021 15.429z"
      fill="#fff"
    />
  </svg>
)
SvgTrudnoca.propTypes = {
  width: PropTypes.number,
}

export default SvgTrudnoca
