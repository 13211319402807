export default [
  {
    text:
      'Najvažnije što mi je pružala doula Sanja bio je razgovor, činjenica da je uz mene žena koja je već kroz sve to prošla i što u točno određenom trenutku zna što treba napraviti. Moj najveći strah bio je da ću svu tu bol morati proći sama. Svima sam rekla da je angažiranje doule nešto najbolje što žena može učiniti za sebe i svoje dijete!',
    name: 'Lidija P. K.',
  },
  {
    text:
      'Pomoć doule Sanje za mene je bila neprocjenjiva - njena smirenost, empatija, veselje i otvorenost. Pomoć u svim segmentima. Najbolja doula na cijelom svijetu…',
    name: 'Ana & Gabrijel',
  },
  {
    text:
      'Cijela trudnoća protekla je bezbrižno i za mene i za supruga, znajući da imamo maksimalnu potporu naše doule Sanje. Njezina podrška bila nam je iznimno dragocjena, ne samo zbog svih stručnih i nadasve korisnih savjeta koje nam je pružila, nego i zbog svoje beskrajno pozitivne energije, mirnoće i radosti. Čim smo se upoznale sam znala da je to osoba koju želim imati uz sebe - smirena, optimistična, vesela i velikog srca, koja će mi pomoći da što opuštenije i mirnije prođe najvažniji događaj mog života.',
    name: 'Lana & Denis',
  },
  {
    text:
      'Kada je napokon došla sve mi je postalo lakše. Odmah sam imala povjerenja da će sve biti u redu, prolazila je sa mnom svaki trud, dodavala mi vode, mazala mi usta labelom jer su bila jako suha, pokrivala me jer mi je bilo jako hladno i stalno me ohrabrivala.',
    name: 'Mia & Matej',
  },
  {
    text:
      'Teško je u par rečenica opisati koliko mi je značilo imati Sanju uz sebe na porodu... Masirala me, disala sa mnom, ali ono što mi je značilo najviše je sigurnost kojom je zračila. Pomogla mi je da ostanem smirena i usredotočena u trenucima kada se bilo lako izgubiti... Uz nju sam se osjećala samopouzdano i snažno, sigurna da će sve proći u najboljem redu. Nakon tog predivnog iskustva, naša obitelj bogatija je za jednog malog dječaka i dragu prijateljicu koja ga je s nama na tako lijep način dočekala!',
    name: 'Ivana & Marin',
  },
  {
    text:
      'Kada je došao taj Dan, iako nije bila angažirana za sam porod, sama se javila mom mužu, ponovila mu nježno sve sto se u tom ludilu može zaboraviti, cijeli dan bila u kontaktu, slala nam podršku i ljubav. I taman kad si pomisliš: "ovo je čudno predobro, možda je ona vanzemaljac", evo je i dalje...nakon poroda, nakon mjesec, dva i pet. Tu je. Za sva pitanja. S osmjehom. Takva kakva jest. Divna. Prijatelj.',
    name: 'Zahvalni, Stefan, Ana i Rita Dimovi',
  },
  {
    text:
      'Bio je to malo duži porod, od ranog jutra do kasno navečer. Ne mogu ni zamisliti kako bi to sve izgledalo da nisam imala svoju doulu Sanju uz sebe. Od ranog jutra u kontaktu sa Sanjom do popodnevnog odlaska u bolnicu pa do dolaska malenog navečer, sve je prošlo kroz zabavu, razgovor i druženje.',
    name: 'Katarina J.',
  },
  {
    text:
      'Tokom poroda, svima nam je potreban glas koji nam daje sugestije oko disanja, promjene položaja i sl. a doula Sanja je bila uz mene cijelo vrijeme, ohrabrujući me, i ja sam se osjećala snažno i sigurno. Svaki je porod drugačiji, voljela bi da smo bile zajedno i na prvom porodu.',
    name: 'Ksenija & Miro',
  },
  {
    text:
      'Draga Sanja, o tebi i tvom radu imam samo riječi hvale! Ni u jednom se trenutku nisam osjećala kao da radiš, već kao da si prijateljica u koju imam puno povjerenje. Nije me bilo strah onoga što dolazi uz takvu toplu i ugodnu osobu pored sebe kao potporu. Preporučila bih te svakome! Još jednom HVALA!',
    name: 'Margareta',
  },
  {
    text:
      'Doula mi je pomogla znati što me čeka na porodu, smiriti se i organizirati u satima trudova kod kuće i bila je točno ono što mi je trebalo na porodu. Porod prošao super, i ostao mi je u lijepom sjećanju kao manifestacija vrhunca ženske snage i sposobnosti ženskog tijela.',
    name: 'Vlatka & Danijel',
  },
]
