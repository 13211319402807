import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSprings, animated } from 'react-spring'
import { colors } from '../theme'

const VerticalSlider = styled.div`
  height: 254px;
  margin: 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.isMobile
      ? `
    flex-direction: row;
    // width: 274px;
    height: auto;
    margin-bottom: 35px;
  `
      : ''}
`

const Numbers = styled.div`
  /* display: flex; */
  font-weight: normal;
  font-size: 12px;
  color: ${colors.greymid};
  text-align: right;
  margin-right: 0.5rem;
  .text {
    width: 32px;
  }
`

const DotContain = styled.div`
  padding: 0.4rem;
  cursor: pointer;
`

const Dot = styled(animated.div)`
  box-sizing: border-box;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: #fff;
`

const DotStack = ({ current, total, setCurrent, isMobile }) => {
  const [springs, setSprings] = useSprings(total, index => ({
    border: '7px solid #f9cad5',
  }))

  setSprings(index =>
    index === current
      ? { border: '2px solid #f9cad5' }
      : { border: '7px solid #f9cad5' }
  )

  return (
    <VerticalSlider isMobile={isMobile}>
      <Numbers>
        <div className="text">
          {current + 1}/{total}
        </div>
      </Numbers>
      {springs.map((props, i) => (
        <DotContain onClick={() => setCurrent(i)}>
          <Dot style={props} />
        </DotContain>
      ))}
    </VerticalSlider>
  )
}
DotStack.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default React.memo(DotStack)
