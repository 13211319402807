import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

const SvgArrow = ({ fill = '#5c5b5f' }) => {
  const smaller = useMedia({ maxWidth: 900 })
  return (
    <svg fill="none" width={smaller ? 16 : 21} viewBox="0 0 21 15">
      <path
        d="M20.707 8.707a1 1 0 000-1.414L14.343.929a1 1 0 10-1.414 1.414L18.586 8l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM0 9h20V7H0v2z"
        fill={fill}
      />
    </svg>
  )
}
SvgArrow.propTypes = {
  fill: PropTypes.string,
}

export default SvgArrow
